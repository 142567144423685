.container{
    background-color:white;
    display:flex;
    flex-direction:column;
    width:20rem;
    height:15rem;
    box-shadow:10px,10px,10px,10px,white;
    border-radius:25px;
    padding:10px;
    justify-content:space-between;
    transition: margin-top .5s, box-shadow 1s;
    margin:10px;


}
.container:hover{
   margin-top:5px;
   -webkit-box-shadow: -5px 3px 44px -15px rgba(0,0,0,1);
   -moz-box-shadow: -5px 3px 44px -15px rgba(0,0,0,1);
   box-shadow: -5px 3px 44px -15px rgba(0,0,0,1);
   

}

.button{
    transition-duration:0.5s;
    background-color:#FE5F55;
    padding:10px;
    display:flex;
    flex-direction:row;
    justify-content:center;
    border-radius:25px;
    justify-self:flex-end;
}

.button:hover{
    background-color:black;
    cursor: pointer;
}
#title{
    margin-block-end: 0.3em;
}


#tool {
    color:#FE5F55;
    text-align:center;
    margin-block-start: 0em;
}

#description {
    color:black;
    text-align:center;
    margin-block-start: 0em;
}



@media screen and (max-width: 524px){
    
}
.project-container{
    background-color: #FE5F55;
    min-height: 100vh;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
  }

  .projectContent{
    display: flex;
    height: 10%;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  .item-container{
    display:flex;
    flex-direction:row;
    justify-content:space-between;

  }

@media screen and (max-width: 800px){

  .item-container{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:space-between;

  }
}